import dynamic from "next/dynamic";
import { Section } from "../index";
import { ReputationGridWrapper } from "./styles";

const ReputationCard = dynamic(() =>
  import("../../elements/reputation-card/reputation-card")
);

export const ReputationGrid = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  cards,
  previousModuleSeparation,
  displaySetting
}) => {
  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <ReputationGridWrapper $separation={previousModuleSeparation}>
        {cards &&
          cards.map((card) => <ReputationCard {...card} key={card.id} />)}
      </ReputationGridWrapper>
    </Section>
  );
};

export default ReputationGrid;

import tw from "tailwind-styled-components";

export const ReputationGridWrapper = tw.ul`
  grid
  grid-cols-1 desktop:grid-cols-3
  gap-4 desktop:gap-6
  list-none

  ${({ $separation }) =>
    $separation
      ? `
      mt-0 desktop:mt-0
      pt-0 desktop:pt-0`
      : `
      -mt-section-spacing-mobile desktop:-mt-section-spacing-desktop
      pt-4 desktop:pt-6
  `}
`;
